import { Routes, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { PageLayout } from "./ui-components/PageLayout";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Loading } from "./ui-components/Loading";
import { QueryClient, QueryClientProvider } from "react-query";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { useEffect } from "react";
import Hotjar from "@hotjar/browser";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    var userId = instance?.getActiveAccount()?.idTokenClaims?.oid;

    if (instance?.initialize) {
      Hotjar.identify(userId, {
        name: instance?.getActiveAccount()?.name,
        email: instance?.getActiveAccount()?.username,
      });
    }
  }, [instance?.getActiveAccount()?.idTokenClaims?.oid]);

  if (!instance || inProgress === "login" || inProgress === "startup") {
    return <Loading />;
  }

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthenticatedTemplate>
            <PageLayout>
              <Pages />
            </PageLayout>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </QueryClientProvider>
      </Provider>
    </>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

export default App;
