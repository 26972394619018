export const QUERIES = {
  Manifests: "manifests",
  Users: "users",
  SavageConverters: "savageConverters",
};

export const MANIFEST_STATUS = {
  DRAFT: 1,
  IN_PROGRESS: 2,
  IN_REVIEW: 3,
  COMPLETED: 4,
};

export const LEADS_STEPS = {
  LOCATION_TYPE: 1,
  START_CONFIRM: 2,
  LEADS_FORM: 3,
  LEADS_CONFIRM: 4,
};

export const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
