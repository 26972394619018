import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Grid, TextField, Select, MenuItem, InputLabel } from "@mui/material";
import UnitedEcosystemFormCard from "../UnitedEcosystemFormCard";
import { FormSectionLabel } from "../FormSectionLabel";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import { US_STATES } from "../../utils/Constants";
import { useMutation } from "react-query";
import LeadsService from "../../services/LeadsService";
import { useSelector } from "react-redux";

const FormStep = ({ onNext }) => {
  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      emailAddress: null,
      companyName: null,
      city: null,
      state: null,
      yardType: null,
      vehiclesAmount: null,
      vehiclesFrequency: null,
      vehiclePurchaseType: null,
      vehiclePurchaseTypeOther: null,
      sellBy: null,
      sellingFrequency: null,
      converterPurchasingType: null,
      descriptionWhoAreYouSellingTo: null,
      descriptionBenefitsInPartnership: null,
      vehiclePrimarySource: null,
      vehiclePrimarySourceOther: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      phoneNumber: Yup.string().required("Required"),
      emailAddress: Yup.string().required("Required"),
      companyName: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      yardType: Yup.number().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log({ ...values, leadSource });
      if (values.sellBy == "false") values.sellBy = false;
      else values.sellBy = true;
      await createLeadMutation.mutateAsync({ ...values, leadSource });
    },
  });

  const createLeadMutation = useMutation((values) => LeadsService.createLead(values), {
    onSuccess: () => {
      formik.resetForm();
      onNext();
    },
  });

  const leadSource = useSelector((state) => state.leads.leadSource);

  return (
    <UnitedEcosystemFormCard>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormSectionLabel label="GENERAL INFORMATION" />
          </Grid>
          <Grid item xs={12}>
            <TextField id="firstName" name="firstName" label="First Name" variant="outlined" fullWidth value={formik.values.firstName} onChange={formik.handleChange} error={formik.touched.firstName && formik.errors.firstName} helperText={"*Required Field"} onBlur={formik.handleBlur} />
          </Grid>
          <Grid item xs={12}>
            <TextField id="lastName" name="lastName" label="Last Name" variant="outlined" fullWidth value={formik.values.lastName} onChange={formik.handleChange} error={formik.touched.lastName && Boolean(formik.errors.lastName)} helperText={"*Required Field"} onBlur={formik.handleBlur} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={"*Required Field"}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="emailAddress"
              name="emailAddress"
              label="Email"
              variant="outlined"
              fullWidth
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
              error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
              helperText={"*Required Field"}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSectionLabel label="YARD INFORMATION" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="companyName"
              name="companyName"
              label="Yard Name"
              variant="outlined"
              fullWidth
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
              helperText={"*Required Field"}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField id="city" name="city" label="City" variant="outlined" fullWidth value={formik.values.city} onChange={formik.handleChange} error={formik.touched.city && Boolean(formik.errors.city)} helperText={"*Required Field"} onBlur={formik.handleBlur} />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
              <Select value={formik.values.state} onChange={(event) => formik.setFieldValue("state", event.target.value)} fullWidth label="State" labelId="demo-simple-select-helper-label" id="demo-simple-select-helper">
                {US_STATES.map((state) => (
                  <MenuItem value={state}>{state}</MenuItem>
                ))}
              </Select>
              <FormHelperText>* Required Field</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} flexDirection={"row"}>
            <FormControl>
              <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
                <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%" }}>Category</Typography>
                <FormLabel sx={{ mt: 1, ml: 1, lineHeight: "120%", letterSpacing: "-0.219%" }}>* Required</FormLabel>
              </Box>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="yardType" onChange={formik.handleChange}>
                <FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Full Serve</Typography>} />
                <FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Self Serve</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={5} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Scrap Yard</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={4} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Collector</Typography>} sx={{ mt: 2 }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%" }}>Volume of Vehicles</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField id="vehiclesAmount" name="vehiclesAmount" label="Vehicle Amount" variant="outlined" fullWidth value={formik.values.vehiclesAmount} onChange={formik.handleChange} error={formik.touched.vehiclesAmount && Boolean(formik.errors.vehiclesAmount)} helperText={"*Required Field"} />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Per</InputLabel>
              <Select value={formik.values.vehiclesFrequency} onChange={formik.handleChange} fullWidth label="Per" labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" name="vehiclesFrequency">
                <MenuItem value={1}>Weekly</MenuItem>
                <MenuItem value={2}>Monthly</MenuItem>
                <MenuItem value={3}>Yearly</MenuItem>
              </Select>
              <FormHelperText>* Required Field</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} flexDirection={"row"}>
            <FormControl>
              <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%", mb: 2 }}>Primary Types of Vehicles Purchased</Typography>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="vehiclePurchaseType" onChange={formik.handleChange}>
                <FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Late Model</Typography>} />
                <FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Early Model</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={3} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Foreign</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={4} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Domestic</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={5} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Other</Typography>} sx={{ mt: 2 }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          {formik.values.vehiclePurchaseType == 5 && (
            <Grid item xs={12}>
              <TextField
                id="vehiclePurchaseTypeOther"
                name="vehiclePurchaseTypeOther"
                label="Other"
                variant="outlined"
                fullWidth
                value={formik.values.vehiclePurchaseTypeOther}
                onChange={formik.handleChange}
                error={formik.touched.vehiclePurchaseTypeOther && Boolean(formik.errors.vehiclePurchaseTypeOther)}
                multiline
                rows={4}
              />
            </Grid>
          )}
          <Grid item xs={12} flexDirection={"row"}>
            <FormControl>
              <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%", mb: 2 }}>Primary Source of Vehicles</Typography>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="vehiclePrimarySource" onChange={formik.handleChange}>
                <FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Copart</Typography>} />
                <FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>IAAI</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={3} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Other Online Auctions</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={4} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>In Person Auctions</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={5} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Impound Lots</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={6} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>The Public</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={7} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Other</Typography>} sx={{ mt: 2 }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          {formik.values.vehiclePrimarySource == 7 && (
            <Grid item xs={12}>
              <TextField
                id="vehiclePrimarySourceOther"
                name="vehiclePrimarySourceOther"
                label="Other"
                variant="outlined"
                fullWidth
                value={formik.values.vehiclePrimarySourceOther}
                onChange={formik.handleChange}
                error={formik.touched.vehiclePrimarySourceOther && Boolean(formik.errors.vehiclePrimarySourceOther)}
                multiline
                rows={4}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormSectionLabel label="CONVERTER INFORMATION" />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%", mb: 2 }}>Primarily Sell By</Typography>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="sellBy" onChange={formik.handleChange}>
                <FormControlLabel value={true} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Piece</Typography>} />
                <FormControlLabel value={false} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>On Assay</Typography>} sx={{ mt: 2 }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%", mb: 2 }}>Average Selling Timeframe</Typography>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="sellingFrequency" onChange={formik.handleChange}>
                <FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Weekly</Typography>} />
                <FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Monthly</Typography>} sx={{ mt: 2 }} />
                <FormControlLabel value={3} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Yearly</Typography>} sx={{ mt: 2 }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%", mb: 2 }}>Primarily Purchase Converter By</Typography>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="converterPurchasingType" onChange={(event) => formik.setFieldValue("converterPurchasingType", event.currentTarget.value)}>
                <FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Piece</Typography>} />
                <FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: 400, lineHeight: "120%", letterSpacing: "-0.219px" }}>Over Scales</Typography>} sx={{ mt: 2 }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormSectionLabel label="PARTNER INFORMATION" />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%", mb: 2 }}>Who are you currently selling converters to?</Typography>
            <TextField id="descriptionWhoAreYouSellingTo" name="descriptionWhoAreYouSellingTo" label="Text" variant="outlined" placeholder="Enter text here" fullWidth value={formik.values.descriptionWhoAreYouSellingTo} onChange={formik.handleChange} multiline rows={4} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 22, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.219%", mb: 2 }}>When considering a partnership, what benefits are you looking for?</Typography>
            <TextField id="descriptionBenefitsInPartnership" name="descriptionBenefitsInPartnership" label="Text" variant="outlined" placeholder="Enter text here" fullWidth value={formik.values.descriptionBenefitsInPartnership} onChange={formik.handleChange} multiline rows={4} />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton variant="contained" size="large" sx={{ height: 56 }} fullWidth disabled={!formik.isValid || formik.isSubmitting || createLeadMutation.isLoading} type="submit" loading={createLeadMutation.isLoading}>
              <Typography sx={{ fontSize: 28, fontWeight: 700, lineHeight: "116.7%" }}>JOIN WAITLIST</Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </UnitedEcosystemFormCard>
  );
};

export default FormStep;
