import { Card, CardMedia, FormControlLabel, IconButton, Switch, Typography, Box, CardContent } from "@mui/material";
import logoFile from "../assets/images/united-ecosystem.png";

const UnitedEcosystemCard = (props) => {
  return (
    <Card sx={{ mt: 10 }}>
      <CardMedia sx={{ height: 140, display: "flex", justifyContent: "center", alignItems: "center" }} image={require("../assets/images/card-header.png")}>
        <Box component="img" src={logoFile} sx={{ height: 40, justifyContent: "center" }} />
      </CardMedia>
      <CardContent sx={{ p: 4 }}>{props.children}</CardContent>
    </Card>
  );
};

export default UnitedEcosystemCard;
