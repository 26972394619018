import { Card, CardMedia, FormControlLabel, IconButton, Switch, Typography, Box, CardContent } from "@mui/material";
import logoFile from "../assets/images/united-ecosystem.png";

const UnitedEcosystemFormCard = (props) => {
  return (
    <Card sx={{ mt: 10, mb: 2 }}>
      <CardMedia sx={{ height: 140, display: "flex", justifyContent: "center", alignItems: "center" }} image={require("../assets/images/form-header.png")}></CardMedia>
      <CardContent sx={{ p: 4 }}>{props.children}</CardContent>
    </Card>
  );
};

export default UnitedEcosystemFormCard;
