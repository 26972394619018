import NavBar from "./NavBar";
import { Container } from "@mui/material";

export const PageLayout = (props) => {
  return (
    <>
      <NavBar />
      <Container maxWidth={false} disableGutters>
        {props.children}
      </Container>
    </>
  );
};
