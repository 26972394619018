import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest } from "../authConfig";
import { Box, Card, CardContent, CardHeader, Container, Grid, useTheme } from "@mui/material";
import logoFile from "../assets/images/united-ecosystem.png";
import bgImage from "../assets/images/login-bg.png";
import { ArrowForward } from "@mui/icons-material";

export const Login = () => {
  const { instance } = useMsal();

  const theme = useTheme();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "flex" }, backgroundColor: theme.palette.primary.main, height: "100vh", justifyContent: "center", alignItems: "center" }}>
          <Box component="img" src={logoFile} alt="leads-form" />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ backgroundImage: `url(${bgImage})`, height: "100vh", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Card>
              <CardHeader title="Sign In" subheader="Use the button below to sign in" />
              <CardContent>
                <Button fullWidth size="large" startIcon={<ArrowForward />} variant="contained" onClick={handleLogin}>
                  SIGN IN
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
