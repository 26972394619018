import Typography from "@mui/material/Typography";
import UnitedEcosystemCard from "../UnitedEcosystemCard";
import { Box, Button } from "@mui/material";
import endStepLogo from "../../assets/images/end-step-logo.png";

const EndStep = ({ onNext }) => {
  return (
    <UnitedEcosystemCard>
      <Typography sx={{ fontSize: 32, fontWeight: 500, lineHeight: "116.7%", letterSpacing: "-1.5px" }}>Thank you for considering us as your trusted partner!</Typography>
      <Box sx={{ justifyContent: "flex-end", display: "flex", mt: 8 }}>
        <Button variant="contained" size="large" sx={{ height: 56, width: "200px" }} onClick={onNext} fullWidth>
          <Typography sx={{ fontSize: 28, fontWeight: 700, lineHeight: "116.7%" }}>DONE</Typography>
        </Button>
      </Box>
    </UnitedEcosystemCard>
  );
};

export default EndStep;
