import { Box, Paper, Typography, useTheme } from "@mui/material";

export const FormSectionLabel = ({ label }) => {
  const theme = useTheme();

  var mainPaperStyle = {
    width: "50%",
    backgroundColor: "#334D72",
    color: theme.palette.primary.contrastText,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    p: 2,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    ml: -4,
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <Paper elevation={9} sx={mainPaperStyle}>
      <Box sx={labelStyle}>
        <Typography sx={{ fontSize: 24, fontWeight: 700, lineHeight: "120%", letterSpacing: "-0.219px" }} textAlign="center">
          {label}
        </Typography>
      </Box>
    </Paper>
  );
};
