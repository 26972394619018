import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import UnitedEcosystemCard from "../UnitedEcosystemCard";
import { Box, Button } from "@mui/material";

const StartConfirmStep = ({ onNext }) => {
  return (
    <UnitedEcosystemCard>
      <Typography sx={{ fontSize: 32, fontWeight: 500, lineHeight: "116.7%", letterSpacing: "-1.5px" }}>When the supplier is ready to enter their information, tap start.</Typography>
      <Box sx={{ justifyContent: "flex-end", display: "flex", mt: 8 }}>
        <Button variant="contained" size="large" sx={{ height: 56, width: "200px" }} onClick={onNext}>
          <Typography sx={{ fontSize: 28, fontWeight: 700, lineHeight: "116.7%" }}>START</Typography>
        </Button>
      </Box>
    </UnitedEcosystemCard>
  );
};

export default StartConfirmStep;
