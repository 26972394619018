import axios from "axios";
// import { PublicClientApplication } from '@azure/msal-browser';
import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";

// Create an Axios instance with default configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Create an Axios interceptor to add the access token to each request
api.interceptors.request.use(async (config) => {
  try {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    // Add the access token to the request headers
    config.headers["Authorization"] = `Bearer ${response.accessToken}`;

    return config;
  } catch (error) {
    // Handle token acquisition error
    console.log("Failed to acquire access token:", error);
    // You may choose to redirect the user to the login page or display an error message
    throw error;
  }
});

export default api;
