import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

export const Loading = ({ percentage = null }) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex", flexDirection: "column", px: 4 }} open={true}>
      {percentage && (
        <Typography variant="h5" sx={{ mb: 2 }}>
          {percentage}%
        </Typography>
      )}
      <CircularProgress variant={percentage ? "determinate" : "indeterminate"} color="inherit" value={percentage} />
    </Backdrop>
  );
};
