import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import UnitedEcosystemCard from "../UnitedEcosystemCard";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLeadSource } from "../../redux/reducers/leadsSlice";
import { useState } from "react";

const LocationTypeStep = ({ onNext }) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState(11);

  const handleChange = (event) => {
    setLocation(event.target.value);
  };

  const handleNext = () => {
    dispatch(setLeadSource(location));
    onNext();
  };

  return (
    <UnitedEcosystemCard>
      <Typography sx={{ fontSize: 32, fontWeight: 500, lineHeight: "116.7%", letterSpacing: "-1.5px" }}>Please indicate what location type you're currently at.</Typography>
      <Box sx={{ ml: 3, mt: 8 }}>
        <FormControl>
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={11} name="radio-buttons-group" onChange={handleChange}>
            <FormControlLabel value={11} control={<Radio size="large" />} label={<Typography sx={{ fontSize: 30, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.5px" }}>Trade Show</Typography>} />
            <FormControlLabel value={7} control={<Radio size="large" />} label={<Typography sx={{ fontSize: 30, fontWeight: 600, lineHeight: "120%", letterSpacing: "-0.5px" }}>Yard Visit</Typography>} sx={{ mt: 4 }} />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ justifyContent: "flex-end", display: "flex", mt: 8 }}>
        <Button variant="contained" size="large" sx={{ height: 56, width: "200px" }} onClick={handleNext}>
          <Typography sx={{ fontSize: 28, fontWeight: 700, lineHeight: "116.7%" }}>NEXT</Typography>
        </Button>
      </Box>
    </UnitedEcosystemCard>
  );
};

export default LocationTypeStep;
