import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { Box, FormControlLabel, IconButton, Switch } from "@mui/material";
import logoFile from "../assets/images/united-ecosystem-blue.png";
import { useMsal } from "@azure/msal-react";
import { ExitToApp } from "@mui/icons-material";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/Constants";
import UserService from "../services/UserService";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveStep } from "../redux/reducers/leadsSlice";
const NavBar = () => {
  const { instance } = useMsal();
  const [showReset, setShowReset] = useState(false);

  const dispatch = useDispatch();
  const onReset = () => {
    dispatch(setActiveStep(1));
    setShowReset(false);
  };

  const { data: user } = useQuery([QUERIES.Users], () => UserService.getMe());

  return (
    <AppBar position="relative" color="inherit">
      <Toolbar>
        {showReset && (
          <IconButton sx={{ mr: 2 }} onClick={() => onReset()} size="large">
            <RestartAltIcon color="error" fontSize="inherit" />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Box component="img" src={logoFile} sx={{ height: 40, mt: 1 }} onDoubleClick={() => setShowReset(!showReset)} />
        </Box>
        <Typography variant="h6">{user?.data?.result?.email}</Typography>
        <IconButton onClick={() => instance.logoutRedirect()} color="primary">
          <ExitToApp />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
