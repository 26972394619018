import { alpha, createTheme } from "@mui/material/styles";

const contrastText = "#fff";

let grey = ["#ffffff", "#fafafa", "#f5f5f5", "#f0f0f0", "#d9d9d9", "#bfbfbf", "#8c8c8c", "#595959", "#262626", "#141414", "#000000"];

const greyColors = {
  0: grey[0],
  50: grey[1],
  100: grey[2],
  200: grey[3],
  300: grey[4],
  400: grey[5],
  500: grey[6],
  600: grey[7],
  700: grey[8],
  800: grey[9],
  900: grey[10],
  A50: grey[15],
  A100: grey[11],
  A200: grey[12],
  A400: grey[13],
  A700: grey[14],
  A800: grey[16],
};

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      light: "rgb(63, 77, 105)",
      main: "#194185",
      dark: "rgb(11, 23, 47)",
      contrastText,
    },
    secondary: {
      main: "#C5CAE9",
      light: "#A0D7F0",
      dark: "#5F90A5",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      light: "#EF5350",
      main: "#D32F2F",
      dark: "#C62828",
      contrastText,
    },
    warning: {
      light: "#FF9800",
      main: "#ED6C02",
      dark: "#E65100",
      contrastText: greyColors[100],
    },
    info: {
      light: "#03A9F4",
      main: "#0288D1",
      dark: "#01579B",
      contrastText,
    },
    success: {
      light: "#4CAF50",
      main: "#28A745",
      dark: "#1B5E20",
      contrastText,
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
    },
    grey: greyColors,
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "Inter",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: "2.375rem",
      lineHeight: 1.21,
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.875rem",
      lineHeight: 1.27,
    },
    h3: {
      fontWeight: 600,
      fontSize: 48,
      lineHeight: "56.02px",
    },
    h4: {
      fontSize: "34px",
      fontWeight: 500,
      lineHeight: "41.99px",
      letterSpacing: "0.25px",
    },
    h5: {
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "24px",
      lineHeight: 1.334,
    },
    h6: {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.15px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
    },
    body1: {
      fontSize: "0.875rem",
      lineHeight: 1.57,
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "0.17px",
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: 1.66,
    },
    overline: {
      lineHeight: 2.66,
      fontWeight: 400,
      fontSize: "12px",
      letterSpacing: "1px",
    },
    button: {
      textTransform: "uppercase",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "16px",
        },
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: alpha("#161D65", 0.6),
            backdropFilter: "blur(6px)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12,
        },
      },
    },
  },
});
