import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeStep: 1,
  leadSource: null,
};

export const stepSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setLeadSource: (state, action) => {
      state.leadSource = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveStep, setLeadSource } = stepSlice.actions;

export default stepSlice.reducer;
